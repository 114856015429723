import { isEmpty } from 'lodash';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'gatsby';
import ApartmentsContext from '../utils/context';
import { getTranslationByKey } from '../helpers/translation-helper';

export default function Floorplan({ data, dir }) {
  const [toggleState, setToggleState] = React.useState(1);
  const [isActive, setIsActive] = React.useState(false);
  const { translations } = React.useContext(ApartmentsContext);
  const [lastSegment, setLastSegment] = React.useState('');

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      let url = window.location.href;
      if (url.endsWith('/')) {
        url = url.slice(0, -1);
      }
      const segment = url.split('/').pop();
      setLastSegment(segment);
    }
  }, []);

  React.useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isActive]);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  const handleClick = () => {
    setIsActive((current) => !current);
  };

  if (isEmpty(data)) return null;

  return (
    <div className="container room-page-container mx-auto relative md:mb-20 mb-10">
      <div className="floor_plan">
        <div
          className="bg-gray93 text-gray py-5 px-6 sm:py-10 md:pb-14 sm:px-12 lg:pt-0 lg:pb-24 lg:px-24 floor_section_wrapper"
          dir={dir}
        >
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-6 ltr:md:pr-24 ltr:pr-10 rto:md:pl-24 rtl:pl-10">
              <div className="title ltr:font-din rtl:font-tahoma subheadline-text ltr:tracking-space12 text-gold font-semibold uppercase md:mb-3 mb-4">
                {data.title}
              </div>
              <p className="ltr:md:text-18-32 rtl:md:text-16-30 text-13-22">{data.text}</p>
              {isEmpty(data.unfurnishedImage) || (
                <div className="tab_button md:mt-12 mt-6" dir={dir}>
                  <button
                    type="button"
                    className={`${
                      toggleState === 1 ? 'tabs active-tabs' : 'tabs'
                    } border-0 w-full text-black mb-2 md:mb-8 block  text-center uppercase btn-highlighted floor-plan-button`}
                    onClick={() => toggleTab(1)}
                    data-buttons={lastSegment}
                    dir={dir}
                  >
                    {getTranslationByKey(translations, 'Furnished')}
                  </button>
                  <button
                    type="button"
                    className={`${
                      toggleState === 2 ? 'tabs active-tabs' : 'tabs'
                    } border-0 w-full text-black mb-2 md:mb-8 block text-center uppercase btn-highlighted floor-plan-button`}
                    onClick={() => toggleTab(2)}
                    data-buttons={lastSegment}
                    dir={dir}
                  >
                    {getTranslationByKey(translations, 'UnFurnished')}
                  </button>
                </div>
              )}
            </div>
            <div className="col-span-12 md:col-span-6 floorplan_imagesection">
              <div className="">
                {isEmpty(data.furnishedImage) || (
                  <div
                    className={`${
                      toggleState === 1 ? 'content  active-content' : 'content'
                    } floorplan_image`}
                  >
                    <Link to="#0" className="imagewrapper block" onClick={handleClick}>
                      <LazyLoadImage src={data.furnishedImage.url} />
                    </Link>
                  </div>
                )}

                {isEmpty(data.unfurnishedImage) || (
                  <div
                    className={`${
                      toggleState === 2 ? 'content  active-content' : 'content'
                    } floorplan_image`}
                  >
                    <Link to="#0" className="imagewrapper block" onClick={handleClick}>
                      <LazyLoadImage src={data.unfurnishedImage.url} />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`${isActive ? 'active' : ''} modal_popup`}>
          <Link to="#0" className="modalsection_wrapper" onClick={handleClick}>
            <Link
              to="#0"
              className="container room-page-containe relative"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="container_wrapper bg-gray93">
                <div className="modal_wrapper">
                  <div className="modal_header_section">
                    {isEmpty(data.unfurnishedImage) || (
                      <div className="tab_button" dir={dir}>
                        <button
                          type="button"
                          className={`${
                            toggleState === 1 ? 'tabs active-tabs' : 'tabs'
                          } border-0 w-full block text-black text-center uppercase btn-highlighted`}
                          onClick={() => toggleTab(1)}
                          data-buttons={lastSegment}
                          dir={dir}
                        >
                          {getTranslationByKey(translations, 'Furnished')}
                        </button>
                        <button
                          type="button"
                          className={`${
                            toggleState === 2 ? 'tabs active-tabs' : 'tabs'
                          } border-0 w-full block text-black text-center uppercase btn-highlighted`}
                          data-buttons={lastSegment}
                          onClick={() => toggleTab(2)}
                          dir={dir}
                        >
                          {getTranslationByKey(translations, 'UnFurnished')}
                        </button>
                      </div>
                    )}

                    <div className="close_button" dir={dir}>
                      <LazyLoadImage
                        src="https://dev-dwtc.euwest01.umbraco.io/media/qccmcy4y/close-icon.png"
                        onClick={handleClick}
                      />
                    </div>
                  </div>
                  <div className="modal_body_section">
                    <div className="modal_body">
                      {isEmpty(data.furnishedImage) || (
                        <div
                          className={`${
                            toggleState === 1 ? 'content  active-content' : 'content'
                          } floorplan_image`}
                        >
                          <LazyLoadImage src={data.furnishedImage.url} />
                        </div>
                      )}
                      {isEmpty(data.unfurnishedImage) || (
                        <div
                          className={`${
                            toggleState === 2 ? 'content  active-content' : 'content'
                          } floorplan_image`}
                        >
                          <LazyLoadImage src={data.unfurnishedImage.url} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </Link>
        </div>
      </div>
    </div>
  );
}
